/** @jsx jsx */

import { jsx } from '../jsx.js';

import { translations } from './translations.js';
import { link } from './domains.js';

const slugs = {
  dp: 'data-protection',
  cp: 'cookies-policy',
  ci: 'cookies-inventory',
};

const urlBuilder = ({ lang, slug }) =>
  `https://www.europarl.europa.eu/privacy-policy/${lang}/${slug}`;

const linksBuilder =
  (url) =>
  ({ lang, dataMoreLink }) => {
    const { textBottom1, textBottom2, textBottom3, linkLabels } =
      translations(lang);

    const firstLink = [
      textBottom1,
      link(dataMoreLink || url({ lang, slug: slugs.dp }), linkLabels[slugs.dp]),
    ];

    return dataMoreLink ? (
      <p>{firstLink}</p>
    ) : (
      <p>
        {firstLink}{' '}
        {[
          textBottom2,
          link(url({ lang, slug: slugs.cp }), linkLabels[slugs.cp]),
        ]}{' '}
        {[
          textBottom3,
          link(url({ lang, slug: slugs.ci }), linkLabels[slugs.ci]),
        ]}{' '}
      </p>
    );
  };

const links = linksBuilder(urlBuilder);
const tag = (_) => '';

export { tag, links, linksBuilder, urlBuilder, slugs };
