import { linksBuilder } from './domain-default.js';

const urlBuilder = ({ lang, slug }) =>
  `https://www.europarl.europa.eu/website/privacy-policy/${lang}/${slug}.html`;

const links = linksBuilder(urlBuilder);

export { tag, linksBuilder, slugs } from './domain-default.js';

export { links, urlBuilder };
