/** @jsx jsx */

import { jsx } from '../jsx.js';

const domainTokens = {
  default: 'europarl',
  engage: 'engage',
};

const link = (url, label) => (
  <a
    href={url}
    style={{ textDecoration: 'underline', color: 'inherit' }}
    target="_blank"
    rel="noopener noreferrer"
    className="cc-link-default"
  >
    {label}
  </a>
);

const domainTag = (label) => (
  <div className="epjs_tag-url">
    <span className="tag-url">{label}</span>
  </div>
);

export { domainTokens, domainTag, link };
