/** @jsx jsx */

import { jsx } from '../jsx.js';

import { translations } from './translations.js';
import { link, domainTag } from './domains.js';

const labels = {
  en: 'European Parliament - together.eu',
  bg: 'Европейски парламент - zaednoza.eu',
  cs: 'Evropský parlament - jsmespolu.eu',
  da: 'Europa-Parlamentet - sammen-om.eu',
  de: 'Europäisches Parlament - gemeinsamfür.eu',
  el: 'Ευρωπαϊκό Κοινοβούλιο - mazi.eu',
  es: 'Parlamento Europeo - todosjuntos.eu',
  et: 'Euroopa Parlament - üheskoos.eu',
  fi: 'Euroopan parlamentti - yhdessä.eu',
  fr: 'Parlement européen - ensemble.eu',
  ga: 'Parlaimint na hEorpa - lecheile.eu',
  hr: 'Europski parlament - zajednoza.eu',
  hu: 'Európai Parlament - europaert.eu',
  it: 'Parlamento europeo - insieme-per.eu',
  lt: 'Europos Parlamentas - esamekartu.eu',
  lv: 'Eiropas Parlaments - mesesamkopa.eu',
  mt: 'Parlament Ewropew - flimkien.eu',
  nl: 'Europees Parlement - samen.eu',
  pl: 'Parlament Europejski - wspolnie.eu',
  pt: 'Parlamento Europeu - unidos.eu',
  ro: 'Parlamentul European - impreuna-in.eu',
  sk: 'Európsky parlament - spolocne.eu',
  sl: 'Evropski parlament - skupajsmo.eu',
  sv: 'Europaparlamentet - tillsammansieuropa.eu',
};

const slugs = {
  ci: ['cookies-inventory', 'cookies'],
  dp: 'data-protection',
  pp: 'privacy-policy',
};

const url = ({ slug, lang }) => `/${lang}/${slug}`;

const links = ({ lang, dataMoreLink }) => {
  const [ciKey, ciSlug] = slugs.ci;
  const { textBottom1, textBottom3, linkLabels } = translations(lang);

  if (dataMoreLink) {
    return <p>{[textBottom1, link(dataMoreLink, linkLabels[slugs.dp])]}</p>;
  }

  return (
    <p>
      {textBottom1} {link(url({ lang, slug: slugs.pp }), linkLabels[slugs.pp])}{' '}
      {textBottom3} {link(url({ lang, slug: ciSlug }), linkLabels[ciKey])}{' '}
    </p>
  );
};

const tag = (lang) => domainTag(labels[lang]);

export { tag, links, slugs, url };
