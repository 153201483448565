const MMC_MARKERS = ['mmc.html', 'multimedia.europarl.europa.eu'];
const PLACEHOLDER_ID = 'consent-popup-placeholder';

// :: ((a -> b), (b -> c)) -> a -> c
const pipe = (f, g) => (x) => g(f(x));

// :: type alias Selector = String
// :: Selector => ()
const setFocus = (selector) => {
  document.querySelector(selector).focus();
};

// :: (Sector, String, String => ()
const setDataAttribute = (selector, name, value) => {
  document.querySelector(selector).dataset[name] = value;
};

// :: () -> String
const lang = () => {
  const { lang } = document.documentElement;
  return lang ? lang.slice(0, 2) : 'en';
};

// :: type alias Location = String
// :: type Context = {tag: String, attribute: String, extention: String}
// :: type alias Basename = String
// :: type SelectorBuilder = Context -> Basename -> Selector

// :: Location -> QueryString -> DOMElement
const findAtLocation = (location) => (selector) =>
  document.querySelector(location).querySelector(selector);

// :: Selector -> DOMElement
const findInHead = findAtLocation('head');

// :: Selector -> DOMElement
const findInBody = findAtLocation('body');

// :: SelectorBuilder
const fileBasedSelector =
  ({ tag, attribute, extension }) =>
  (basename) =>
    `${tag}[${attribute}*="${basename}${
      basename.endsWith(extension) ? '' : extension
    }"]`;

// :: Basename -> Selector
const cssSelector = fileBasedSelector({
  tag: 'link',
  attribute: 'href',
  extension: '.css',
});

// :: Basename -> Selector
const scriptSelector = fileBasedSelector({
  tag: 'script',
  attribute: 'src',
  extension: '.js',
});

// :: Basename -> DOMElement
const findCssLink = pipe(cssSelector, findInHead);

// :: Basename -> DOMElement
const findScriptInHead = pipe(scriptSelector, findInHead);

// :: Basename -> DOMElement
const findScriptInBody = pipe(scriptSelector, findInBody);

const findScript = (basename) =>
  findScriptInHead(basename) || findScriptInBody(basename);

const scriptAttribute = (basename, attribute) => {
  const scriptElement = findScript(basename);
  return scriptElement && scriptElement.getAttribute(attribute);
};

const scriptDataset = (basename) => {
  const scriptElement = findScript(basename);
  return scriptElement && scriptElement.dataset;
};

const hasMarker = (markers, location) =>
  markers.some((item) => location.includes(item));

const cssUrlSuffix = (location) =>
  hasMarker(MMC_MARKERS, location) ? '-mmc' : '';

const cssUrlFromScript = (origin, destination) => {
  const dest = destination || origin;
  const basename = `${dest}${cssUrlSuffix(document.location.href)}`;

  const scriptElement = findScript(origin);

  return scriptElement
    ? scriptElement.src
        .split('/')
        .slice(0, -1) // eslint-disable-next-line unicorn/prefer-spread
        .concat(`${basename}.css`)
        .join('/')
    : null;
};

const createCssLink = (url, onLoad, onError) => {
  // Creation de l'appel du fichier CSS
  const c = document.createElement('link');
  c.setAttribute('rel', 'stylesheet');
  c.setAttribute('type', 'text/css');
  c.setAttribute('href', url);
  // Ajout des evenements de chargement
  c.addEventListener('load', onLoad, false);
  c.addEventListener('error', onError, false);
  return c;
};

const injectInHead = (cssLinkOrScript) => {
  document.querySelector('head').append(cssLinkOrScript);
};

const trackerUrl = (basename) => {
  const script = findScript(basename);
  return script ? script.dataset.value : '';
};

const trackerLoaded = (basename) => {
  const url = trackerUrl(basename);
  return url === '' ? false : findScriptInHead(url);
};

const createTrackerScript = (url, className) => {
  const theNode = document.createElement('script');
  theNode.setAttribute('type', 'text/javascript');
  theNode.setAttribute('defer', '');
  theNode.setAttribute('src', url);
  theNode.setAttribute('class', 'tracker-node-' + className);
  return theNode;
};

const injectTracker = (basename, className) => {
  const url = trackerUrl(basename);
  if (url === '' || findScript(url)) {
    return;
  }

  injectInHead(createTrackerScript(url, className));
};

const removeElement = (selector) => {
  const tag = document.querySelector(selector);
  if (tag) {
    tag.remove();
  }
};

const removeScript = (basename) => {
  const tasks = [findScriptInHead, findScriptInBody];
  for (const task of tasks) {
    const script = task(basename);
    if (script) {
      script.remove();
    }
  }
};

const removeCssLink = (basename) => {
  const link = findCssLink(basename);
  if (link) {
    link.remove();
  }
};

function removeScriptAndCss(basename) {
  const tasks = [
    [removeScript, basename],
    [removeCssLink, basename],
  ];

  for (const task of tasks) {
    task[0](task[1]);
  }
}

const normalizedDomain = () =>
  location.hostname.includes('europarl.europa.eu')
    ? '.europarl.europa.eu'
    : location.hostname.replace('www', '');

const insertPopupPlaceholder = () => {
  const placeholder = document.createElement('div');
  placeholder.setAttribute('id', PLACEHOLDER_ID);
  document.body.insertBefore(placeholder, document.body.firstChild);
};

const insertPopupPlaceholderIfNeeded = () => {
  if (!findInBody(`#${PLACEHOLDER_ID}`)) {
    insertPopupPlaceholder();
  }
};

const transitionEndEventName = () => {
  const bodyStyle = document.body.style;
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  return Object.keys(transitions).reduce(
    (acc, transition) =>
      acc === undefined && bodyStyle[transition] !== undefined
        ? transitions[transition]
        : acc,
    undefined
  );
};

export {
  pipe,
  lang,
  setFocus,
  setDataAttribute,
  findAtLocation,
  findInHead,
  findInBody,
  fileBasedSelector,
  cssSelector,
  scriptSelector,
  findCssLink,
  findScriptInHead,
  findScriptInBody,
  findScript,
  scriptAttribute,
  scriptDataset,
  cssUrlSuffix,
  cssUrlFromScript,
  createCssLink,
  trackerUrl,
  trackerLoaded,
  createTrackerScript,
  injectInHead,
  injectTracker,
  removeElement,
  removeScript,
  removeCssLink,
  removeScriptAndCss,
  normalizedDomain,
  PLACEHOLDER_ID,
  insertPopupPlaceholder,
  insertPopupPlaceholderIfNeeded,
  transitionEndEventName,
};
