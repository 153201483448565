/** @jsx jsx */
/* globals define */

import { app } from 'hyperapp';

import { jsx } from '../jsx.js';
import { lang, findInBody } from '../dom.js';

import { cookieHandler } from './cookies.js';
import { translations } from './widget-translations.js';
import { OPTED_IN, OPTED_OUT, DNTED, NO_CHOICE } from './constants.js';

const PLACEHOLDER_SELECTOR = '#ep-opt-js';

const descriptionLabels = {
  [OPTED_IN]: 'optInDesc',
  [OPTED_OUT]: 'optOutDesc',
  [NO_CHOICE]: 'chooseDesc',
  [DNTED]: 'dntDesc',
};

const descriptionLabel = ({ lang, status }) =>
  translations(lang)[descriptionLabels[status]];

const labels = (lang) => {
  const { optInLabel, optOutLabel } = translations(lang);
  return { optInLabel, optOutLabel };
};

const Update = (effecters) => (state, status) =>
  [
    {
      ...state,
      status,
    },
    [effecters],
  ];

const OnChange = (effecters) => (_, event) =>
  [Update(effecters), event.target.value];

const widget =
  ({ lang, optInLabel, optOutLabel, OptIn, OptOut }) =>
  ({ status }) =>
    (
      <div class="ep_gridcolumn-content" id="ep-opt-js">
        <form action="" id="ep_opt" class="ep_opt-form">
          <fieldset>
            <div class="ep-a_text">
              <p>{descriptionLabel({ lang, status })}</p>
            </div>
            {status !== DNTED && (
              <div class="ep_cookies-form-field">
                <input
                  id="a11y-issue-1"
                  name="cookies-validation"
                  type="radio"
                  value={OPTED_OUT}
                  onchange={OptOut}
                  checked={status === OPTED_OUT}
                />
                <label for="a11y-issue-1">{optOutLabel}</label>
              </div>
            )}
            {status !== DNTED && (
              <div class="ep_cookies-form-field">
                <input
                  id="a11y-issue-2"
                  name="cookies-validation"
                  type="radio"
                  value={OPTED_IN}
                  onchange={OptIn}
                  checked={status === OPTED_IN}
                />
                <label for="a11y-issue-2">{optInLabel}</label>
              </div>
            )}
          </fieldset>
        </form>
      </div>
    );

const buildWidget = ({ lang, OptIn, OptOut }) =>
  widget({
    lang,
    ...labels(lang),
    OptIn,
    OptOut,
  });

const widgetHandler = ({ status, optInFx, optOutFx }) => {
  const props = {
    status,
    lang: lang(),
    OptIn: OnChange(optInFx),
    OptOut: OnChange(optOutFx),
  };

  const displayWidget = () => {
    app({
      init: { status },
      view: buildWidget(props),
      node: findInBody(PLACEHOLDER_SELECTOR),
    });
  };

  if (typeof define === 'function' && define.amd) {
    define('privacy-policy', () => ({
      addOptIO: displayWidget,
    }));
  } else {
    displayWidget();
  }
};

const widgetController = () => {
  const [status, { optInFx, optOutFx }] = cookieHandler();

  if (findInBody(PLACEHOLDER_SELECTOR)) {
    widgetHandler({ status, optInFx, optOutFx });
    return true;
  }

  return false;
};

export {
  descriptionLabel,
  labels,
  widget,
  buildWidget,
  widgetHandler,
  widgetController,
};
