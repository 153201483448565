const OPTED_IN = 'in';
const OPTED_OUT = 'out';
const DNTED = 'dnt';
const NO_CHOICE = 'choose';

const JS_AND_CSS_BASENAME = 'privacy-policy';
const domainTokens = {
  default: 'europarl',
  engage: 'engage',
  ukraine: 'ukraine',
};

export {
  OPTED_IN,
  OPTED_OUT,
  DNTED,
  NO_CHOICE,
  JS_AND_CSS_BASENAME,
  domainTokens,
};
