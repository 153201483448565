import { h, text } from 'hyperapp';

const textify = (x) =>
  typeof x === 'string' || typeof x === 'number' ? text(x) : x;

const jsx = (type, props, ...children) =>
  typeof type === 'function'
    ? type(props, children)
    : // eslint-disable-next-line unicorn/no-array-callback-reference
      h(type, props || {}, children.flat().map(textify));

export { jsx };
