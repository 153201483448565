import { findInBody, removeElement, setFocus } from './dom.js';

const slideIn = (_) => ({ displayed: true, dismissed: false });
const slideOut = (effecters) => (_) =>
  [{ displayed: false, dismissed: true }, [effecters]];

const setListener = (dispatch, props) => {
  const element = findInBody(props.selector);
  const listener = (event) =>
    requestAnimationFrame(() => dispatch(props.dispatchable, event));

  if (element) {
    element.addEventListener(props.type, listener);
    return () => element.removeEventListener(props.type, listener);
  }

  return () => {};
};

const removePopup = (dispatch, options) => {
  requestAnimationFrame(() => {
    removeElement(options.selector);
    dispatch(() => undefined);
  });
};

const removePopupFx = (selector) => [removePopup, { selector }];

const setFocusOnElement = (/* dispatch */ _, options) => {
  requestAnimationFrame(() => {
    setFocus(options.selector);
  });
};

const setFocusFx = (selector) => [setFocusOnElement, { selector }];

export { slideIn, slideOut, setListener, setFocusFx, removePopupFx };
